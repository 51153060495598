<template>
    <b-card>
        <b-form @submit.prevent="onSubmit">
            <div class="partner-create-invoice">
                <div :class="{ error: v$.fromDate.$errors.length }" class="form-group">
                    <label for="">{{ $t("exports.fromDate") }}</label>
                    <datepicker
                        v-model="fromDate"
                        :enableTimePicker="false"
                        :maxDate="maxDate"
                        class="form-input"
                        format="dd. MM. yyyy"
                        previewFormat="dd. MM. yyyy"
                    ></datepicker>
                    <div
                        class="input-errors"
                        v-for="error of v$.fromDate.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </div>

                <div :class="{ error: v$.toDate.$errors.length }" class="form-group">
                    <label for="">{{ $t("exports.toDate") }}</label>
                    <datepicker
                        v-model="toDate"
                        :enableTimePicker="false"
                        :maxDate="maxDate"
                        class="form-input"
                        format="dd. MM. yyyy"
                        previewFormat="dd. MM. yyyy"
                    ></datepicker>
                    <div
                        class="input-errors"
                        v-for="error of v$.toDate.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </div>

                <div :class="{ error: v$.name.$errors.length }" class="form-group">
                    <label for="">{{ $t("forms.name") }}</label>
                    <b-form-input v-model="name"/>
                    <div
                        class="input-errors"
                        v-for="error of v$.name.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </div>

                <b-button variant="primary" @click="onSubmit">Vytvoriť faktúru</b-button>
            </div>
        </b-form>
    </b-card>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import useVuelidate from "@vuelidate/core";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import Datepicker from "@vuepic/vue-datepicker";
import Toast from "@/components/Toast.vue";
import moment from "moment";
import "@vuepic/vue-datepicker/dist/main.css";
import {useToast} from "vue-toastification";

export default {
    props: {
        partnerObj: { type: Object, default: null}
    },
    components: {
        Datepicker,
    },
    data() {
        const today = new Date();
        return {
            pdf: null,
            name: '',
            templateName: 'INVOICE_PARTNER',
            resource: this.partnerObj['@id'],
            fromDate: new Date(new Date().setDate(today.getDate() - 30)),
            toDate: today,
            maxDate: today,
            types: [],
        };
    },
    mixins: [ListUtils, ResourceUtils],
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            name: {required: this.$translateError("required")},
            fromDate: {required: this.$translateError("required")},
            toDate: {required: this.$translateError("required")},
        };
    },
    watch: {
        pdf() {
            this.$emit("pdf", this.pdf);
        },
        name(newValue) {
            this.$nextTick(() => {
                this.name = this.removeSpecialCharacters(newValue);
            })
        },
    },
    methods: {
        removeSpecialCharacters(charactersString) {
            return charactersString.replace(/[^a-zA-Z0-9--]/g, "");
        },
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                this.fromDate.setHours(0);
                this.fromDate.setMinutes(0);
                this.fromDate.setSeconds(0);
                this.fromDate.setMilliseconds(0);

                this.toDate.setHours(23);
                this.toDate.setMinutes(59);
                this.toDate.setSeconds(59);
                this.toDate.setMilliseconds(0);

                const body = {
                    name: this.name,
                    templateName: this.templateName,
                    partner: this.resource,
                    fromDate: moment(this.fromDate).format(), // fromDate: moment(this.fromDate).format('YYYY-MM-DD'),
                    toDate: moment(this.toDate).format(),
                };

                this.$Pdfs.createResourceByUrl({ url: "/pdf/invoice-partner", body })
                    .then((response) => {
                        if (response.status === 200) {
                            this.pdf = response.data
                            const link = document.createElement("a");
                            link.href = this.$helper.getUrlPreventBrowserCache(response.data.notCachedUrl);
                            link.target = "_blank";
                            link.click();
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            this.$helper.showToast(
                                useToast(),
                                Toast,
                                error.response.data["hydra:title"],
                                error.response.data["hydra:description"],
                                "danger"
                            );
                        }
                    });
            }
            this.$emit("clearAction");
        },
        success() {
            this.$emit("clearAction");
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>
