<template>
  <div v-if="partner">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('forms.save')"
      :title="$t('partner.update')"
      @submitPressed="submitPressed"
    />
    <div class="partner-custom-form">
      <partner-form
        :partner-obj="partner"
        :custom-fields-obj="customFields"
        :action="action"
        @clearAction="action = null"
      />
    </div>
    <partner-create-invoice-form :partner-obj="partner" />
    <invoice-list :partner-obj="partner" />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import PartnerForm from "./components/PartnerForm.vue";
import InvoiceList from "@/views/Partner/Invoices/list.vue"
import PartnerCreateInvoiceForm from "@/views/Admin/Partners/components/PartnerCreateInvoiceForm.vue";

export default {
  components: {
      PartnerCreateInvoiceForm,
    TitleBar,
    PartnerForm,
    InvoiceList
  },
  data() {
    return {
      action: null,
      partner: null,
      customFields: {
        'partner_contractUrl': {type: "STRING", value: ''},
        'partner_pricelistUrl': {type: "STRING", value: ''},
        'partner_emailMessage': {type: "STRING", value: ''},
        'partner_activationMessage': {type: "STRING", value: ''},
        'redeem_voucher': {type: "BOOLEAN", value: 'false'},
        'discounted_flights': {type: "BOOLEAN", value: 'false'},
        'record': {type: "BOOLEAN", value: 'false'},
        'invoices': {type: "BOOLEAN", value: 'false'}
      },
    };
  },
  created() {
    this.loadPartner();
  },
  methods: {
    async loadPartner() {
      const id = this.$route.params.id;
      const response = await this.$Users.getResource({ id })
      this.partner = response.data
      if (!this.partner.address) {
        this.partner.address = {
          streetAddress: "",
          city: "",
          postalCode: "",
          country: ""
        }
      }
      if (this.partner.customFields) {
        for (const cf of this.partner.customFields) {
          this.customFields[cf.name] = cf
        }
      }
    },
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
